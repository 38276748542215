import React, { Component } from 'react';
import ProjectItem from './ProjectItem';

import projects from '../data/data.json';

class Projects extends Component {
  render() {
    return (
      <div className="projects">
        <h1>Projects</h1>
        <div className="project-list">
          {this.renderPojectList()}
        </div>
      </div>
    );
  }

  renderPojectList() {
    var result = [];

    console.log(projects.data);

    for(var i = 0; i < projects.data.length; i++) {
      result.push(<ProjectItem
        key={'project_' + i}
        name={projects.data[i].name}
        image={projects.data[i].image}
        video={projects.data[i].video}
        description={projects.data[i].description}
        responsibilities={projects.data[i].responsibilities}
        title={projects.data[i].title}
        tech={projects.data[i].tech}
        links={projects.data[i].links}
        styles={projects.data[i].styles}
        />);
    }

    return result;
  }
}

export default Projects;
