import React, { Component } from 'react';
import Video from 'react-player';

class ProjectItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: props.name,
      image: props.image,
      video: props.video,
      description: props.description,
      responsibilities: props.responsibilities,
      title: props.title,
      tech: props.tech,
      links: props.links,
      styles: props.styles
    };

    this.showDetails = this.showDetails.bind(this);
  }

  render() {
    return (
      <div className="project-item" onClick={this.showDetails}>
        <h2>{this.state.name}</h2>
        <div className="displayArea">{this.getImageOrVideo()}</div>
        <p>{this.state.title}</p>
        <p>{this.state.description} {this.state.responsibilities}</p>
        <p className="italics">{this.state.tech}</p>
        <div className={(this.state.links.length > 0) ? 'links' : 'links hide-div'}>Links: {this.renderLinks()}</div>
      </div>
    );
  }

  getImageOrVideo() {
    if (typeof this.state.image !== 'undefined')
      return (<img src={this.state.image} className={('displayImage ' + this.state.styles)} alt={this.state.name}></img>);
    
    if (typeof this.state.video !== 'undefined') {
      return (
        <Video
          url={this.state.video}
          volume={0}
          muted={true}
          playing={true}
          playsinline={true}
          loop={true}
          width="100%"
          height="100%"
        />);
    }

    return;
  }

  showDetails() {
    console.log('Show details for project: ' + this.state.name);
  }

  renderLinks() {
    var results = [];

    if (typeof this.state.links === 'undefined')
      return results;

    for(var i = 0; i < this.state.links.length; i++) {
      results.push(<a href={this.state.links[i].target} target="_blank" rel="noopener noreferrer">{this.state.links[i].text}</a>)

      if (i+1 < this.state.links.length) results.push(' | ');
    }
    
    return results;
  }
}

export default ProjectItem;
