import React, { Component } from 'react';

class About extends Component {
  render() {
    return (
      <div className="about">
        <div className="about-wrapper">
          <h1>About</h1>
          <p>I love VR development and have produced high quality VR experiences for gamers and consumers alike. I have 11 years of software development experience where I have managed the front end user experience through to the code architecture ranging from enterprise scale projects servicing hundreds of thousands users per month to games and prototypes.</p>
        </div>
      </div>
    );
  }
}

export default About;
