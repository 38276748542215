import React, { Component } from 'react';

class Contact extends Component {
  render() {
    return (
      <div className="header">
        <div className="navigation">
          <div className="logo"><h3>Tyler Matchett</h3></div>
          <div><a href="tel:19023291588" target="_blank" rel="noopener noreferrer"><i className="fas fa-phone"></i></a></div>
          <div><a href="mailto:hello@tylermatchett.com" target="_blank" rel="noopener noreferrer"><i className="fas fa-paper-plane"></i></a></div>
          <div><a href="https://www.linkedin.com/in/tylermatchett/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i></a></div>
          <div><a href="https://github.com/tylermatchett" target="_blank" rel="noopener noreferrer"><i className="fab fa-github"></i></a></div>
        </div>
        <div className="hero">
          <div className="overlay">
            <h1>Hey, I'm Tyler. I design and build VR and software experiences.</h1>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
