import React, { Component } from 'react';
import Header from './components/Header';
import About from './components/About';
import Projects from './components/Projects';

import './App.css';

class App extends Component {
  render() {
    return (
      <div className="app">
        <header>
          <Header />
        </header>
        <main>
          <About />
          <Projects />
        </main>
      </div>
    );
  }
}

export default App;
